/**
 * To do:
 * Progetta interno (x):
 * -- Pianeta Arte: (x)
 * -- Pianeta Coding: (x)
 * -- Pianeta ???: (x)
 */

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { gsap } from "gsap"
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';

import Planet1Basecolor from '/textures/planet1/basecolor.jpg';
import Planet1Metallic from '/textures/planet1/metallic.jpg';
import Planet1Height from '/textures/planet1/height.jpg';
import Planet1Normal from '/textures/planet1/normal.jpg';
import Planet1Alpha from '/textures/planet1/alpha.jpg';

import Planet2Diff from '/textures/planet2/diff.jpg';
import Planet2Rough from '/textures/planet2/rough.jpg';
import Planet2Disp from '/textures/planet2/disp.jpg';
import Planet2Normal from '/textures/planet2/normal.jpg';
import Planet2Bump from '/textures/planet2/bump.jpg';
import Planet2AO from '/textures/planet2/ao.jpg';

import Planet3basecolor from '/textures/planet3/basecolor.jpg';
import Planet3roughness from '/textures/planet3/roughness.jpg';
import Planet3height from '/textures/planet3/height.png';
import Planet3normal from '/textures/planet3/normal.jpg';
import Planet3ambientOcclusion from '/textures/planet3/ambientOcclusion.jpg';

import Planet4COLOR from '/textures/planet4/COLOR.jpg';
import Planet4DISP from '/textures/planet4/DISP.png';
import Planet4NORM from '/textures/planet4/NORM.jpg';
import Planet4OCC from '/textures/planet4/OCC.jpg';
import Planet4ROUGH from '/textures/planet4/ROUGH.jpg';

import Planet5COLOR from '/textures/planet5/COLOR.jpg';
import Planet5ROUGH from '/textures/planet5/ROUGH.jpg';
import Planet5DISP from '/textures/planet5/DISP.png';
import Planet5NORM from '/textures/planet5/NORM.jpg';
import Planet5OCC from '/textures/planet5/OCC.jpg';

import Planet6basecolor1 from '/textures/planet6/basecolor1.jpg';
import Planet6normal from '/textures/planet6/normal.jpg';
import Planet6ambientOcclusion from '/textures/planet6/ambientOcclusion.jpg';

import alphaSunImg from '/textures/alphaSun.jpg';
import alphaCloudsImg from '/textures/alphaClouds.jpg';

import sako from '/sako.svg';
import guardaCheLuce from '../../images/GuardaCheLuceCheHai-Low.jpg';

import photom1 from '../../images/photom(1).jpg';
import photom2 from '../../images/photom(2).jpg';
import photom3 from '../../images/photom(3).jpg';
import photom4 from '../../images/photom(4).jpg';
import photom5 from '../../images/photom(5).jpg';
import photom6 from '../../images/photom(6).jpg';
import photom7 from '../../images/photom(7).jpg';
import photom8 from '../../images/photom(8).jpg';
import photom9 from '../../images/photom(9).jpg';
import photom10 from '../../images/photom(10).jpg';
import photom11 from '../../images/photom(11).jpg';
import photom12 from '../../images/photom(12).jpg';

const photoms = [
    photom1,
    photom2,
    photom3,
    photom4,
    photom5,
    photom6,
    photom7,
    photom8,
    photom9,
    photom10,
    photom11,
    photom12
]


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Utils
 */
//Functions

function enterFullscreen(fullscreenElement) {
    if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
    } else if (fullscreenElement.mozRequestFullScreen) { /* Firefox */
        fullscreenElement.mozRequestFullScreen();
    } else if (fullscreenElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        fullscreenElement.webkitRequestFullscreen();
    } else if (fullscreenElement.msRequestFullscreen) { /* IE/Edge */
        fullscreenElement.msRequestFullscreen();
    }
}


function exitFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
    }
}

function repeatTexture(object, repeat) {

    function propertyRepeat(property) {
        if (object[property]) {
            object[property].repeat.set(repeat, repeat)
            object[property].wrapS = THREE.RepeatWrapping
            object[property].wrapT = THREE.RepeatWrapping
        }
    }

    propertyRepeat("Diff")
    propertyRepeat("Alpha")
    propertyRepeat("Rough")
    propertyRepeat("Disp")
    propertyRepeat("Nor")
    propertyRepeat("Bump")
    propertyRepeat("AO")
    propertyRepeat("Spec")
    propertyRepeat("Col")
    propertyRepeat("Subsur")
    propertyRepeat("Metal")
}

function IDToConst(id) {
    const element = document.getElementById(id);
    if (element) {
        window[id] = element;
    } else {
        console.error("Element with ID '" + id + "' not found.");
    }
}

/* Scroll shadow functions */
    function addShadowsOnScroll(scrollableDiv, topShadow, bottomShadow) {
        return function() {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;

            if (Math.ceil(scrollTop) + Math.ceil(clientHeight) < Math.ceil(scrollHeight)) {
                bottomShadow.classList.remove('opacity-0');
            } else {
                bottomShadow.classList.add('opacity-0');
            }
            
            if (scrollTop > 0) {
                topShadow.classList.remove('opacity-0');
            } else {
                topShadow.classList.add('opacity-0');
            }
        }
    }
/**
 * Environment map
 */
const rgbeLoader = new RGBELoader()
const environmentMap = rgbeLoader.load("./envMaps/StarField.hdr");
rgbeLoader.load("./envMaps/StarField.hdr", (environmentMap) => {
    environmentMap.mapping = THREE.EquirectangularReflectionMapping;

    scene.environment = environmentMap;
    scene.background = environmentMap;
});

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();

let planet1 = {};
planet1.Diff = textureLoader.load(Planet1Basecolor);
planet1.Rough = textureLoader.load(Planet1Metallic);
planet1.Metal = textureLoader.load(Planet1Metallic);
planet1.Disp = textureLoader.load(Planet1Height);
planet1.Bump = textureLoader.load(Planet1Height);
planet1.Nor = textureLoader.load(Planet1Normal);
planet1.Alpha = textureLoader.load(Planet1Alpha);

let planet2 = {};
planet2.Diff = textureLoader.load(Planet2Diff);
planet2.Rough = textureLoader.load(Planet2Rough);
planet2.Disp = textureLoader.load(Planet2Disp);
planet2.Nor = textureLoader.load(Planet2Normal);
planet2.Bump = textureLoader.load(Planet2Bump);
planet2.AO = textureLoader.load(Planet2AO);
repeatTexture(planet2, 2);

let planet3 = {};
planet3.Diff = textureLoader.load(Planet3basecolor);
planet3.Rough = textureLoader.load(Planet3roughness);
planet3.Disp = textureLoader.load(Planet3height);
planet3.Nor = textureLoader.load(Planet3normal);
planet3.AO = textureLoader.load(Planet3ambientOcclusion);

let planet4 = {};
planet4.Col = textureLoader.load(Planet4COLOR);
planet4.Disp = textureLoader.load(Planet4DISP);
planet4.Nor = textureLoader.load(Planet4NORM);
planet4.AO = textureLoader.load(Planet4OCC);
planet4.Rough = textureLoader.load(Planet4ROUGH);

let planet5 = {};
planet5.Diff = textureLoader.load(Planet5COLOR);
planet5.Rough = textureLoader.load(Planet5ROUGH);
planet5.Disp = textureLoader.load(Planet5DISP);
planet5.Nor = textureLoader.load(Planet5NORM);
planet5.AO = textureLoader.load(Planet5OCC);

let planet6 = {};
planet6.Diff = textureLoader.load(Planet6basecolor1);
planet6.Nor = textureLoader.load(Planet6normal);
planet6.AO = textureLoader.load(Planet6ambientOcclusion);

const alphaSun = textureLoader.load(alphaSunImg);
const alphaClouds = textureLoader.load(alphaCloudsImg);

/**
 * Objects
 */

const sun = new THREE.Mesh(
    new THREE.SphereGeometry(0.3, 32, 32),
    new THREE.MeshStandardMaterial({
        color: '#ffff00',
        transparent: true,
        alphaMap: alphaSun,
        emissive: 0xffff00,
        emissiveIntensity: 2,
        side: THREE.DoubleSide
    })
)

scene.add(sun)

const object1 = new THREE.Mesh(
    new THREE.SphereGeometry(0.5, 32, 32),
    new THREE.MeshStandardMaterial({
        map: planet1.Diff,
        roughnessMap: planet1.Rough,
        metalness: 0.5,
        normalMap: planet1.Nor,
        side: THREE.DoubleSide,
        transparent: true,
        alphaMap: planet1.Alpha,
        bumpMap: planet1.Bump,
        envMap: environmentMap,
    })
)
object1.position.x = 0
object1.position.z = - 3

const object2 = new THREE.Mesh(
    new THREE.SphereGeometry(0.4, 32, 32),
    new THREE.MeshStandardMaterial({
        map: planet2.Diff,
        roughnessMap: planet2.Rough,
        displacementMap: planet2.Disp,
        displacementScale: 0.05,
        normalMap: planet2.Nor,
        bumpMap: planet2.Bump,
        aoMap: planet2.AO,
        aoMapIntensity: 2,
        side: THREE.DoubleSide,
        envMap: environmentMap,
        envMapIntensity: 10,
    })
)
object2.position.x = 2.5905
object2.position.z = -1.5265

const object3 = new THREE.Mesh(
    new THREE.SphereGeometry(0.7, 32, 32),
    new THREE.MeshStandardMaterial({
        color: '#88b6ea',
        map: planet3.Diff,
        roughnessMap: planet3.Rough,
        displacementMap: planet3.Disp,
        displacementScale: 0.001,
        normalMap: planet3.Nor,
        aoMap: planet3.AO,
        aoMapIntensity: 1,
        side: THREE.DoubleSide,
        envMap: environmentMap,
    })
)
object3.position.x = 2.5905
object3.position.z = 1.5265

const object4 = new THREE.Mesh(
    new THREE.SphereGeometry(0.7, 32, 32),
    new THREE.MeshStandardMaterial({
        color: "#2f96d7",
        map: planet4.Col,
        displacementMap: planet4.Disp,
        displacementScale: 0.01,
        normalMap: planet4.Nor,
        aoMap: planet4.AO,
        aoMapIntensity: 1,
        roughnessMap: planet4.Rough,
        side: THREE.DoubleSide,
        envMap: environmentMap,
    })
)
object4.position.x = 0
object4.position.z = 3

const object5 = new THREE.Mesh(
    new THREE.SphereGeometry(0.4, 32, 32),
    new THREE.MeshStandardMaterial({
        map: planet5.Diff,
        roughness: 0,
        metalness: 0.7,
        displacementMap: planet5.Disp,
        displacementScale: 0.1,
        normalMap: planet5.Nor,
        aoMap: planet5.AO,
        aoMapIntensity: 1,
        side: THREE.DoubleSide,
        envMap: environmentMap,
    })
)
object5.position.x = -2.5905
object5.position.z = 1.5265

const object5Atmosphere = new THREE.Mesh(
    new THREE.SphereGeometry(0.55, 32, 32),
    new THREE.MeshStandardMaterial({
        color: '#ffffff',
        emissive: 0xffffff,
        emissiveIntensity: 0.2,
        transparent: true,
        alphaMap: alphaClouds,
        side: THREE.DoubleSide,
        envMap: environmentMap,
    })
)

object5Atmosphere.position.x = -2.5905
object5Atmosphere.position.z = 1.5265
scene.add(object5Atmosphere)

const object6 = new THREE.Mesh(
    new THREE.SphereGeometry(0.6, 32, 32),
    new THREE.MeshStandardMaterial({
        color: '#7d9ba1',
        map: planet6.Diff,
        roughness: 0.4,
        metalness: 0.8,
        normalMap: planet6.Nor,
        aoMap: planet6.AO,
        aoMapIntensity: 10,
        side: THREE.DoubleSide,
        envMap: environmentMap,
    })
)
object6.position.x = -2.5905
object6.position.z = -1.5265


const objectToTest = [object1, object2, object3, object4, object5, object6]
for (const object of objectToTest) {
    object.rotation.z = 35;
}

const group = new THREE.Group()
group.add(object1, object2, object3, object4, object5, object6)

scene.add(group)


/**
 * Lights
 */

const ambientLight = new THREE.AmbientLight('#FFFFFF', 0.9)

scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(3, 2, 3)
const directionalLight1 = new THREE.DirectionalLight('#ffffff', 0.5)
directionalLight.position.set(-3, -2, -3)
const directionalLight2 = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(3, -2, -3)
const directionalLight3 = new THREE.DirectionalLight('#ffffff', 0.5)
directionalLight.position.set(-3, -2, 3)

scene.add(directionalLight)
scene.add(directionalLight1)
scene.add(directionalLight2)
scene.add(directionalLight3)

const pointLight = new THREE.PointLight('#ffaaaa', 200)
scene.add(pointLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
camera.position.y = 4
camera.lookAt(0, 0, 0)

let cameraOrbiting = true
let distance = 6;

// Set the initial angle
let angle = 0;

// Function to update camera position
function updateCameraPosition() {
    // Calculate the new position based on the angle
    const x = distance * Math.sin(angle);
    const z = distance * Math.cos(angle);

    // Update camera position
    camera.position.set(x, 4, z);

    // Point the camera at the object
    camera.lookAt(0, 0, 0);
}

// Render loop
function animate() {
    if (!cameraOrbiting) {
        return; // Exit the function if not orbiting
    }
    requestAnimationFrame(animate);

    // Update the angle
    angle += 0.002;

    // Update camera position
    updateCameraPosition();

}

// Start the animation
animate();


scene.add(camera)

// Function to start/stop camera orbiting
function toggleCameraOrbiting() {
    cameraOrbiting = !cameraOrbiting;
    if (cameraOrbiting) {
        animate(); // Start orbiting
    }
}

IDToConst('enterFullscreenBtn');
IDToConst('exitFullscreenBtn');
enterFullscreenBtn.addEventListener('click', () => {
    enterFullscreen(universe);
})
exitFullscreenBtn.addEventListener('click', () => {
    exitFullscreen();
})

IDToConst('universeTitle');

IDToConst('universe');
IDToConst('enterUnivBtn');

IDToConst("universeControls");

IDToConst('nextBtn');
IDToConst('prevBtn');
IDToConst('exitUnivBtn');
IDToConst('enterPlanetBtn');
IDToConst('exitPlanetBtn');


IDToConst("door1");
IDToConst("door3");

let isUniverseOpen = false;
let isFullscreen = false;

function updateButtonVisibility() {
    if (isUniverseOpen && !isFullscreen) {
        enterFullscreenBtn.classList.remove('hidden');
        exitFullscreenBtn.classList.add('hidden');
    } else if (isUniverseOpen && isFullscreen) {
        enterFullscreenBtn.classList.add('hidden');
        exitFullscreenBtn.classList.remove('hidden');
    } else {
        enterFullscreenBtn.classList.add('hidden');
        exitFullscreenBtn.classList.add('hidden');
    }
}

document.addEventListener('fullscreenchange', (event) => {
    isFullscreen = !!document.fullscreenElement;
    updateButtonVisibility();
});

document.addEventListener('webkitfullscreenchange', (event) => {
    isFullscreen = !!document.webkitFullscreenElement;
    updateButtonVisibility();
});

document.addEventListener('mozfullscreenchange', (event) => {
    isFullscreen = !!document.mozFullScreenElement;
    updateButtonVisibility();
});

document.addEventListener('MSFullscreenChange', (event) => {
    isFullscreen = !!document.msFullscreenElement;
    updateButtonVisibility();
});

function toggleUniverse() {
    isUniverseOpen = !isUniverseOpen;
    updateButtonVisibility();
}

enterUnivBtn.addEventListener("click", () => {
    toggleUniverse()
    universeTitle.classList.add("disappear");
    enterUnivBtn.style.display = "none";
    door1.classList.add("openingLeft");
    door3.classList.add("openingRight");
    enterFullscreen(universe)
    toggleCameraOrbiting()
    gsap.to(camera.position, { x: 5.2, z: 3, y: 0.2, duration: 3 });
    setTimeout(() => {
        universeControls.classList.add('flex');
        universeControls.classList.remove("hidden");
        planetTitle.innerHTML = planets[currentPositionIndex];
        universeTitle.style.display = "none";
    }, 2000)
})

exitUnivBtn.addEventListener("click", () => {
    toggleUniverse()
    gsap.to(camera.position, { x: 0.07, z: 6, y: 4, duration: 1 });
    universeControls.classList.add('hidden');
    universeControls.classList.remove("flex");
    angle = 0;
    setTimeout(() => { toggleCameraOrbiting() }, 1000)
    currentPositionIndex = 2
    door1.classList.add("closing");
    door3.classList.add("closing");
    setTimeout(() => {
        door1.classList.remove("openingLeft", "closing");
        door3.classList.remove("openingRight", "closing");
        universeTitle.style.display = "block";
        universeTitle.classList.remove("disappear");
        enterUnivBtn.style.display = "block";
    }, 2000)
    exitFullscreen()
})

const planets = ["Arte", "Fotografia", "Scrittura", "Formazione", "???", "Coding"];
IDToConst("planetTitle");

let currentPositionIndex = 2
const positions = [
    { x: 0, z: -6, duration: 1 },
    { x: 5.2, z: -3, duration: 1 },
    { x: 5.2, z: 3, duration: 1 },
    { x: 0, z: 6, duration: 1 },
    { x: -5.2, z: 3, duration: 1 },
    { x: -5.2, z: -3, duration: 1 }
]
prevBtn.onclick = function () {
    currentPositionIndex++
    if (currentPositionIndex >= positions.length) {
        currentPositionIndex = 0
    }
    gsap.to(camera.position, positions[currentPositionIndex])

    let counter = Math.floor(Math.random() * 6)
    planetTitle.style.color = "#396291";
    planetTitle.innerHTML = planets[counter];
    const titlesInterval = setInterval(() => {
        if (counter < 5) {
            counter++
        } else { counter = 0 }
        planetTitle.innerHTML = planets[counter];
    }, 50)
    setTimeout(() => {
        clearInterval(titlesInterval);
        planetTitle.innerHTML = planets[currentPositionIndex];
        planetTitle.style.color = "#ffffff";
    }, 800)
}
nextBtn.onclick = function () {
    currentPositionIndex--
    if (currentPositionIndex < 0) {
        currentPositionIndex = positions.length - 1
    }
    gsap.to(camera.position, positions[currentPositionIndex])
    let counter = Math.floor(Math.random() * 6)
    planetTitle.style.color = "#396291";
    planetTitle.innerHTML = planets[counter];
    const titlesInterval = setInterval(() => {
        if (counter < 5) {
            counter++
        } else { counter = 0 }
        planetTitle.innerHTML = planets[counter];
    }, 50)
    setTimeout(() => {
        clearInterval(titlesInterval);
        planetTitle.innerHTML = planets[currentPositionIndex];
        planetTitle.style.color = "#ffffff";
    }, 800)
}

let imagesCounter = 0;

const planetInsideContent = [
    `
    <p>La scrittura ha sempre fatto parte di me, fin da quando da ragazzino, ascoltando hip hop, scrivevo rime di rabbia e tristezza, tipici sentimenti di un perfetto adolescente a disagio.<br>
    <br>Oggi scrivo ancora, qualche volta, ma tutto ciò che scrivo è rivolto alla felicità, alla consapevolezza e alla gioia di vivere in questo meraviglioso mondo.
    <br>Mi piace scrivere storie che possano essere belle favole per bambini e piccoli insegnamenti per i grandi. Credo nella terapia della lettura e nei benefici della coltivazione del nostro bambino interiore.</p>
    <br>
    <p>Ho destato la tua curiosità? <button id="openTale" class="underline action">Vedi "<span class='text-yellow-400'>Guarda che luce che hai!</span>"</button> in collaborazione con <a href="https://sakobfs.wixsite.com/sakobfs" target="_blank" class="align-bottom pl-1"><img class="h-[30px] w-auto transition-all hover:bg-action" src="${sako}"></a></p>
    <br><br>
    <div class="text-center">
        <a href="https://fonzhousefonzie.gumroad.com/" target="_blank" class="underline action"><i class="fa-solid fa-book-open"></i> Scopri tutte le storie!</a>
    </div>
    <div id="tale" class="hidden absolute top-0 left-0 w-full h-full z-[99] bg-background-color overflow-auto transition-transform scale-0">
        <div class="flex justify-between gap-8 mt-8 mb-4">
            <button class="ml-10 p-2 hover:underline" id="closeTale"><i class="fa fa-solid fa-angle-left"></i> Indietro</button>
            <a href="https://fonzhousefonzie.gumroad.com/l/guarda-che-luce-che-hai" target="_blank" class="btn text-center">Clicca qui per leggerla in alta qualità!</a>
            <div class="w-[88px]"></div>
            </div>
        <div class="h-4/5 w-full">
            <img class="pointer-events-none h-full w-full object-contain" src="${guardaCheLuce}">
        </div>
    </div>
    `,
    `
    <p>C'è stato un periodo che ero convinto di fare della fotografia il mio lavoro della vita.
    <br>L'ho fatto - per un piccolo periodo - e mi sono reso conto che non fa per me. Nel frattempo, però, ho imparato e adorato fare fotomontaggi e, in qualche modo, questo mi ha portato ad un lavoro di fotomontatore per un'azienda in cui facevo anche il colorista di fumetti.
    <br>Non male per un daltonico. <i class="fa-solid fa-face-laugh-beam"></i>
    <br><br>
    Qualche tempo dopo, durante il lockdown, vivevo vicino alla natura, e questa fortuna mi ha permesso di scoprire ed amare la fotografia degli animali, cosa che oggi mi appassiona più di tutto quando prendo in mano la macchina fotografica.</p>
    <br>
    <!-- <div class="text-center text-xl">
        <a href="#" target="_blank" class="action"><i class="fa-brands fa-kickstarter"></i> <span class="underline">Sostieni il mio progetto su Kickstarter!</span></a>
    </div> -->
    <div class="text-center">
        <a href="https://unsplash.com/it/@fonzhousefonzie" target="_blank" class="action"><i class="fa-brands fa-unsplash"></i> <span class="underline">Visita il mio Unsplash</span></a>
    </div>
    <p class="pb-2 pt-4">Alcuni dei miei fotomontaggi:</p>
    <div class="w-full flex">
        <div id="photoPrev" class="px-4 text-xl action hover:text-2xl flex items-center justify-center"><i class="fa fa-solid fa-angle-left"></i></div>
        <div id="photoGallery" class="w-full h-[480px]">
            <img src="${photoms[0]}" class="photom-0 w-full h-full object-contain transition-all">
            <img src="${photoms[1]}" class="photom-1 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[2]}" class="photom-2 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[3]}" class="photom-3 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[4]}" class="photom-4 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[5]}" class="photom-5 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[6]}" class="photom-6 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[7]}" class="photom-7 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[8]}" class="photom-8 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[9]}" class="photom-9 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[10]}" class="photom-10 opacity-0 hidden w-full h-full object-contain transition-all">
            <img src="${photoms[11]}" class="photom-11 opacity-0 hidden w-full h-full object-contain transition-all">
        </div>
        <div id="photoNext" class="px-4 text-xl action hover:text-2xl flex items-center justify-center"><i class="fa fa-solid fa-angle-right"></i></div>
    </div>
    <p class="pt-2">
    Un ringraziamento speciale a tutte le persone che hanno voluto prendere parte e hanno arricchito la mia arte fotografica. <i class="fa fa-solid fa-heart heart-beating text-red-400"></i> <br>
    <br>In particolare, presenti in queste foto:</p>
    <ul class="list-disc pl-8">
        <li><i class="fa fa-solid fa-heart text-xs align-baseline pr-2"></i> Maria Elena Guerriero</li>
        <li><i class="fa fa-solid fa-heart text-xs align-baseline pr-2"></i> Amirka Del Monte Rodriguez</li>
        <li><i class="fa fa-solid fa-heart text-xs align-baseline pr-2"></i> Erica Mancini</li>
        <li><i class="fa fa-solid fa-heart text-xs align-baseline pr-2"></i> Lorenzo Leccese</li>
        <li><i class="fa fa-solid fa-heart text-xs align-baseline pr-2"></i> Francesca Piccinni</li>
    </ul>
    `,
    `<i class="fa-solid fa-gear animate-spin"></i> Questa sezione è un work in progress!`,
    `<i class="fa-solid fa-gear animate-spin"></i> Questa sezione è un work in progress!`,
    `<i class="fa-solid fa-gear animate-spin"></i> Questa sezione è un work in progress!`,
    `
    <p>Tutti mi hanno sempre detto che sono <em class="text-secondary">bravo a spiegare le cose</em> e <em class="text-secondary">paziente</em>, mi hanno chiesto e consigliato più volte di insegnare ciò che conoscevo.
    <br>Io, ad essere sinceri, non mi sentivo all'altezza del compito, insomma, formare una persona è una grande responsabilità, gigantesca direi!
    <br>
    <br>Ma alla fine mi sono convinto e mi ci sono buttato, <span class="text-blue-400">nel grande mare della formazione. <i class="boat fa-solid fa-sailboat"></i></span>
    <br>
    <br>Oggi insegno coding e cerco di trasmettere la magia della programmazione, un'arte che secondo me può essere il giusto punto d'incontro tra la logica e la libera espressione della fantasia.
    <br>
    <br>Tra una lezione e l'altra mi sono reso conto che quest'arte ha bisogno di poter essere condivisa con tutti e per questo sto progettando un software che possa aiutare sia gli insegnanti che gli studenti di tutto il mondo. <i class="fa-solid fa-earth-africa"></i></p>
    `,
];

IDToConst("planetInside");
IDToConst("planetInsideTitle");
IDToConst("planetInsideDiv");

enterPlanetBtn.onclick = function () {

    universeControls.classList.add('hidden');
    universeControls.classList.remove("flex");

    let planetContent;
    switch (planetTitle.innerHTML) {
        case "Scrittura":
            planetContent = planetInsideContent[0];
            setTimeout(() => {
                IDToConst("openTale");
                IDToConst("tale");
                openTale.onclick = function () {
                    tale.classList.remove("hidden");
                    setTimeout(() => {
                        tale.classList.replace("scale-0", "scale-100");
                    }, 100);
                };
                IDToConst("closeTale");
                closeTale.onclick = function () {
                    tale.classList.replace("scale-100", "scale-0");
                    setTimeout(() => {
                        tale.classList.add("hidden");
                    }, 100);
                };
            }, 400);
            break;

        case "Fotografia":
            planetContent = planetInsideContent[1];
            imagesCounter = 0;
            setTimeout(() => {
                // Scroll shadow
                IDToConst('planetInsideScrollDiv');
                IDToConst('scrollShadowBottom');
                IDToConst('scrollShadowTop');
                scrollShadowBottom.classList.remove('opacity-0');
                planetInsideScrollDiv.addEventListener('scroll', addShadowsOnScroll(planetInsideScrollDiv, scrollShadowTop, scrollShadowBottom))

                // Content
                let photom = document.querySelector('.photom-' + imagesCounter);
                IDToConst('photoGallery');
                IDToConst('photoPrev');
                IDToConst('photoNext');

                photoPrev.onclick = function () {
                    photom.classList.add('opacity-0');
                    setTimeout(() => {
                        photom.classList.add('hidden');
                        imagesCounter > 0 ? imagesCounter-- : imagesCounter = 11;
                        photom = document.querySelector('.photom-' + imagesCounter);
                        photom.classList.remove('hidden');
                        photom.classList.remove('opacity-0');
                    }, 200)
                }

                photoNext.onclick = function () {
                    photom.classList.add('opacity-0');
                    setTimeout(() => {
                        photom.classList.add('hidden');
                        imagesCounter < 11 ? imagesCounter++ : imagesCounter = 0;
                        photom = document.querySelector('.photom-' + imagesCounter);
                        photom.classList.remove('hidden');
                        photom.classList.remove('opacity-0');
                    }, 200)
                }
            }, 400);
            break;

        case "Arte":
            planetContent = planetInsideContent[2];
            break;

        case "Coding":
            planetContent = planetInsideContent[3];
            break;

        case "???":
            planetContent = planetInsideContent[4];
            break;

        case "Formazione":
            planetContent = planetInsideContent[5];
            break;

        default:
            planetContent = "<p>Sezione in fase di manutenzione.</p>";
    }

    setTimeout(() => {
        planetInside.classList.remove("hidden");
        planetInside.classList.add("flex");
        planetInsideTitle.innerHTML = planetTitle.innerHTML;
        planetInsideDiv.innerHTML = planetContent;
    }, 300)

    let enterPosition = {}
    switch (currentPositionIndex) {
        case 0:
            enterPosition = { x: object1.position.x, z: object2.position.z - 2 }
            break
        case 1:
            enterPosition = { x: object2.position.x, z: object2.position.z }
            break
        case 2:
            enterPosition = { x: object3.position.x, z: object3.position.z }
            break
        case 3:
            enterPosition = { x: object4.position.x, z: object4.position.z }
            break
        case 4:
            enterPosition = { x: object5.position.x, z: object5.position.z }
            break
        case 5:
            enterPosition = { x: object6.position.x, z: object6.position.z }
            break
    }

    gsap.to(camera.position, enterPosition)

}

exitPlanetBtn.onclick = function () {

    planetInside.classList.remove("flex");
    planetInside.classList.add("hidden");
    if(scrollShadowBottom){
        scrollShadowBottom.classList.add("opacity-0");
    } else if(scrollShadowTop) {
        scrollShadowTop.classList.add("opacity-0");
    }

    setTimeout(() => {
        universeControls.classList.add('flex');
        universeControls.classList.remove("hidden");
    }, 1000)

    gsap.to(camera.position, positions[currentPositionIndex])
}

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


const renderScene = new RenderPass(scene, camera);

const params = {
    strength: 0.05,
    radius: 0.2,
    threshold: 0.00001
}

const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 0.05, 0.2, 0.00001);
bloomPass.threshold = params.threshold;
bloomPass.strength = params.strength;
bloomPass.radius = params.radius;

const outputPass = new OutputPass();

let composer = new EffectComposer(renderer);
composer.addPass(renderScene);
composer.addPass(bloomPass);
composer.addPass(outputPass);

/* WebGL Context Loss */

canvas.addEventListener('webglcontextrestored', function(event) {
    event.preventDefault();
    window.location.reload();
}, false);

/**
 * Animate
 */

const tick = () => {

    for (const object of objectToTest) {
        object.rotateY(0.001)
    }

    object5Atmosphere.rotation.y += 0.002
    object5Atmosphere.rotation.z += 0.002
    object5Atmosphere.rotation.x -= 0.002

    // Update controls
    controls.update()

    // Render
    composer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()